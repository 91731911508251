<template>
  <div v-if="entity && entity.information">
    <div class="services-header">
      <div>
        <a-radio-group v-model="filterBy">
          <a-radio-button value="all">All</a-radio-button>
          <a-radio-button value="rooms">Rooms</a-radio-button>
          <a-radio-button value="services">Services</a-radio-button>
        </a-radio-group>
      </div>

      <div class="mr-4 d-none d-sm-block kart" v-if="services.length > 0">
        <div style="">
          <p>Signin or create an account to proceed with any bookings.</p>
        </div>
      </div>
    </div>

    <div v-if="entity.services && services.length">
      <div v-if="showRooms && rooms.length > 0" style="margin-bottom: 20px">
        <h1>Rooms</h1>

        <div class="grid-container">
          <shared-service-or-room-card
            class="service-card"
            v-for="(service, index) of rooms"
            :key="index"
            :service="service"
            :is_anonymouse="true"
          />
        </div>
      </div>

      <div v-if="showServices && hotelServices.length > 0">
        <h1>Services</h1>

        <div class="grid-container">
          <shared-service-or-room-card
            class="service-card"
            v-for="(service, index) of hotelServices"
            :key="index"
            :service="service"
            :is_anonymouse="true"
          />
        </div>
      </div>
    </div>

    <div v-else>
      <h4>{{ entity.information.name }} has no Services yet</h4>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { get } from "lodash";
import { GET_ENTITY } from "@/store/actions";

import SharedServiceOrRoomCard from "@/components/tourist/SharedServiceOrRoomCard.vue";

export default {
  components: {
    SharedServiceOrRoomCard,
  },
  props: {
    entity_type: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {
      loading: false,

      showPaymentModal: false,
      showRentalPaymentModal: false,
      showCashPaymentNotification: false,

      entity: {},
      currentService: {},
      kart: [],
      cachedKart: [],

      cashPaymentCode: "",
      transactionId: "",
      filterBy: "all",
    };
  },
  computed: {
    services() {
      if (this.entity && this.entity.services) {
        const services = this.entity.services.map((r) => ({
          ...r,
          service_type: "service",
        }));

        let rooms = get(this.entity, "rooms", []);

        rooms = rooms.map((r) => ({
          ...r,
          service_type: "room",
        }));

        return [...services, ...rooms].filter((serviceOrRoom) => {
          if (serviceOrRoom.service_type === "room") {
            return (
              serviceOrRoom.status === "active" &&
              serviceOrRoom.fully_booked === false
            );
          }
          return serviceOrRoom.status === "active";
        });
      }
      return [];
    },
    rooms() {
      return this.services.filter(
        (service) => service.service_type && service.service_type === "room"
      );
    },
    hotelServices() {
      return this.services.filter(
        (service) => !service.service_type || service.service_type !== "room"
      );
    },
    entityId() {
      return this.$route.params.id;
    },
    showRooms() {
      return ["rooms", "all"].includes(this.filterBy);
    },
    showServices() {
      return ["services", "all"].includes(this.filterBy);
    },
  },
  created() {
    this.fetchEntity();
  },
  methods: {
    ...mapActions("tourist", {
      getEntity: GET_ENTITY,
    }),
    ...mapMutations("tourist", {
      setTouristState: "SET_STATE",
    }),
    async fetchEntity() {
      try {
        this.loading = true;
        const response = await this.getEntity({
          entity_type: this.entity_type,
          entity_id: this.$route.params.id,
        });
        this.entity = response.entity;
      } catch (error) {
        this.$notification.error({ message: "Error fetching entity data" });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-self: auto;
}

.service-card {
  /* margin-left: 5px; */
  margin-right: 25px;
}

.services-header {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-right: 130px;
  margin-bottom: 20px;
}
</style>
