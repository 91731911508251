<template>
  <div class="card-wrapper">
    <div class="card" @click="showImageLightBox(0)">
      <img
        v-if="!hasFeaturedImages"
        class="absolute"
        :src="getFeaturedImage()"
      />

      <a-carousel autoplay v-else>
        <div
          class="carousel-outer"
          v-for="featuredPos in [0, 1, 2]"
          :key="featuredPos"
          :style="
            getValueFromSource(service, `featured_images[${featuredPos}].path`)
              ? `background: url(${getValueFromSource(
                  service,
                  `featured_images[${featuredPos}].path`
                )})`
              : ''
          "
        >
          <p
            style="color: white; font-weight: bold"
            v-if="
              !getValueFromSource(
                service,
                `featured_images[${featuredPos}].path`
              )
            "
          >
            No Image
          </p>
        </div>
      </a-carousel>

      <vue-easy-lightbox
        :visible="visibleImagePreviewBox"
        :imgs="previewImages"
        :index="activePreviewIndex"
        @hide="handleHide"
      ></vue-easy-lightbox>

      <div class="absolute outer">
        <div></div>
        <div>
          <p class="title">{{ service.title }}</p>
        </div>
      </div>
    </div>

    <p class="mx-2 text-wrap">{{ service.description }}</p>

    <hr
      v-if="service.service_type === 'tour' && service.enable_additional_fields"
    />
    <div
      v-if="service.service_type === 'tour' && service.enable_additional_fields"
      class=""
    >
      <div class="mx-2">
        <p>
          <strong>Departure Date: </strong>
          {{
            service.departure_date ? formatDate(service.departure_date) : "N/A"
          }}
        </p>

        <p>
          <strong>Departure Time: </strong>
          {{ service.departure_time || "N/A" }}
        </p>

        <p>
          <strong>Arrival Date: </strong>
          {{ service.arrival_date ? formatDate(service.arrival_date) : "N/A" }}
        </p>

        <p>
          <strong>Arrival Time: </strong> {{ service.arrival_time || "N/A" }}
        </p>
      </div>
    </div>

    <hr v-if="service.service_type === 'events'" />
    <div v-if="service.service_type === 'events'" class="">
      <div class="mx-2">
        <p>
          <strong>Start Date: </strong>
          {{
            service.event_start_date
              ? formatDate(service.event_start_date)
              : "N/A"
          }}
        </p>

        <p>
          <strong>Start Time: </strong>
          {{ service.event_start_time || "N/A" }}
        </p>

        <p>
          <strong>End Date: </strong>
          {{
            service.event_end_date ? formatDate(service.event_end_date) : "N/A"
          }}
        </p>

        <p>
          <strong>End Time: </strong>
          {{ service.event_end_time || "N/A" }}
        </p>
      </div>
    </div>

    <hr v-if="service.service_type === 'transport'" />
    <div v-if="service.service_type === 'transport'" class="">
      <div class="mx-2">
        <p>
          <strong>Vehicle Type: </strong>
          {{ service.vehicle_type || "N/A" }}
        </p>

        <p>
          <strong>Vehicle Model: </strong>
          {{ service.vehicle_model || "N/A" }}
        </p>

        <p>
          <strong>Vehicle Year: </strong>
          {{ service.vehicle_year || "N/A" }}
        </p>
      </div>
    </div>

    <hr />
    <div class="d-flex justify-content-between align-items-center my-4 mx-2">
      <div class="">
        <span class="price">{{ service.price | formatCurrency }}</span>
      </div>

      <div v-if="!is_anonymouse">
        <span v-if="!addedToKart">
          <a-button
            v-if="
              (service.service_type && service.service_type === 'room') ||
              is_vehicle
            "
            class="pay-btn"
            type="primary"
            size="small"
            @click="$emit('checkout-now')"
            >{{ is_vehicle ? "Rent Now" : "Book Now" }}</a-button
          >
          <a-button
            v-else
            class="pay-btn"
            type="primary"
            size="small"
            @click="$emit('add-to-kart')"
            >Add to Cart</a-button
          >
        </span>
        <a-button
          v-else
          class="pay-btn"
          type="primary"
          size="small"
          @click="$emit('remove-from-kart')"
          >Remove from Cart</a-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/helpers";
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    VueEasyLightbox,
  },
  props: {
    service: {
      type: Object,
      default: () => ({}),
    },
    addedToKart: {
      type: Boolean,
      required: false,
      default: false,
    },
    is_vehicle: {
      type: Boolean,
      required: false,
      default: false,
    },
    is_anonymouse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visibleImagePreviewBox: false,
      activePreviewIndex: 0,
    };
  },
  computed: {
    hasFeaturedImages() {
      const featured_images = this.getValueFromSource(
        this.service,
        "featured_images"
      );
      if (featured_images) {
        return true;
      }

      return false;
    },
    previewImages() {
      if (this.hasFeaturedImages) {
        const paths = [];

        [0, 1, 2].forEach((i) => {
          const path = this.getValueFromSource(
            this.service,
            `featured_images[${i}].path`
          );
          const name = this.getValueFromSource(
            this.service,
            `featured_images[${i}].name`
          );

          paths.push({
            src: path,
            name,
          });
        });
        return paths;
      }

      return [
        {
          src: this.getValueFromSource(this.service, `featured_image.path`),
          name: this.getValueFromSource(this.service, `featured_image.name`),
        },
      ];
    },
  },
  methods: {
    handlePayment() {
      this.$emit("pay-for-service");
    },
    handlerCheckout() {
      this.$emit("checkout-now");
    },
    getFeaturedImage() {
      if (this.service) {
        if (this.service.featured_images) {
          return this.getServiceFeaturedImage();
        }
        // service.featured_image.path
        if (this.service && this.service.featured_image) {
          return this.service.featured_image.path || "";
        }
        return "";
      }
    },
    getServiceFeaturedImage() {
      if (this.service.featured_images) {
        for (const image of this.service.featured_images) {
          if (image && image.path && image.path.length > 0) {
            return image.path;
          }
        }
      } else {
        return this.service.featured_image
          ? this.service.featured_image.path
          : "";
      }
      return "";
    },
    showImageLightBox(index) {
      this.visibleImagePreviewBox = index;
      this.visibleImagePreviewBox = true;
    },
    handleHide() {
      this.visibleImagePreviewBox = false;
    },
    formatDate,
  },
};
</script>

<style scoped>
.absolute {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.outer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;
  width: inherit;
  padding: 15px;
}

.carousel-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
  padding: 15px;
  background-color: rgba(0, 0, 0, 0.2);
  height: 160px;
}

img {
  height: 230px;
  max-height: 230px;
  width: 350px;
  max-width: 350px;
  opacity: 0.75;
  object-fit: cover;
  position: center;
}
.card-wrapper {
  height: auto;
  width: 350px;
  background-color: rgb(231, 231, 231);
  margin-bottom: 20px;
}
.card {
  height: 230px;
  max-height: 230px;
  width: 350px;
  max-width: 350px;
  color: white;
  background: black;

  transition: 0.3s;
  border-radius: 5px;
  cursor: pointer;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.ratings {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  align-items: baseline;
  align-content: flex-end;
}

.rating {
  color: white;
  font-weight: bolder;
  font-size: 1.3rem;
  margin-left: 10px;
}

.title {
  font-weight: bolder;
  font-size: 1.4rem;
}

.price {
  font-size: 1.5rem;
}

.payments {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
</style>
